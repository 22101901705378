




import { Component, Vue } from "vue-property-decorator";

import EntityCrud from "../entity/EntityCrud.vue";
import servicePlanModel from "@/api/service_plan.model";
import { ServicePlanKind } from "@/interfaces/service_plan";

@Component({
	components: { EntityCrud },
})
export default class ServicesPlan extends Vue {
	model: any = servicePlanModel;

	title = "Serviços";
	tableColumns = ["serviceKind", { key: "insurerPlan", name: "Plano" }];

	formStyleVariant = "primary";

	formColumns = [
		"serviceKind",
		{
			key: "otherServiceKind",
			name: "Outro tipo de serviço",
			isReadOnly: false,
			validationToShow: (entity: any) => {
				return entity.serviceKind === ServicePlanKind.OTHERS;
			},
		},
		"description",
		"insurerPlan",
	];
}
